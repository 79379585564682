<template>
  <div class="bigDiv" @click="moveButton($event)">
    <van-overlay :show="fenxiangshow" @click="fenxiangshow = false">
      <div class="wrapper">
        <div class="block">
          <img src="../../assets/images/BIM/fx.png" alt="">
        </div>
      </div>
    </van-overlay>
    <div class="topImg">
      <img src="../../assets/images/BIM/BIMBGC.png" alt="">
    </div>
    <div class="form">
      <div class="title">
        BMI计算器
      </div>
      <!-- <div class="gender">
  <van-radio-group v-model="form.sex" direction="horizontal" checked-color="#00c298">
  <van-radio name="1" style="margin-right:150px">男</van-radio>
  <van-radio name="2">女</van-radio>
</van-radio-group>
</div>
<div class="infos">
  <div class="title">年龄</div>
<van-field v-model="form.age" placeholder="请输入年龄" >
  <template #right-icon>  <div style="color:#00c298">岁</div> </template>
</van-field>
</div> -->
      <div class="infos">
        <div class="title">身高</div>
        <van-field v-model="form.height" placeholder="请输入身高">
          <template #right-icon>
            <div style="color:#00c298">厘米</div>
          </template>
        </van-field>
      </div>
      <div class="infos">
        <div class="title">体重</div>
        <van-field v-model="form.weight" placeholder="请输入体重">
          <template #right-icon>
            <div style="color:#00c298">斤</div>
          </template>
        </van-field>
      </div>

      <div class="btn">
        <div class="rest" @click="rest">
          清空
        </div>
        <div class="sub" @click="submit">计算</div>
      </div>
    </div>
    <div class="floating-button" ref="floatingButton" @click="logs">
      <img src="../../assets/images/BIM/xuanfu.gif" alt="" style="width:40px;">``
    </div>
  </div>
</template>

<script>
import { getWechatConfig } from "@/api/yangsheng";

import { Toast } from 'vant';
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      fenxiangshow: false,
      xuanfu: false,
      buttonPosition: {
        top: 0,
        left: 0
      },
      form: {
        sex: '1',
        age: '18',
        height: '',
        weight: ''
      }
    }
  },
  methods: {
    logs () {
      this.fenxiangshow = true
    },
    getWechatConfig () {
      console.log(wx, 888888888);

      const query = {
        company_id: 1,
        url: window.location.href
      }
      getWechatConfig(query).then(res => {
        const agent = res.data.data
        if (res.data.code === 200) {
          this.$nextTick(() => {

          })
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature,// 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData,'onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
          });

          this.share()
        }

      }
      )
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: '营养食疗BMI计算器', // 分享标题
            link: 'https://kf.hshwhkj.com/bmiIndex?', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '快速测评BMI', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '营养食疗BMI计算器', // 分享标题
            link: 'https://kf.hshwhkj.com/bmiIndex', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '快速测评BMI', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          })

        });
        wx.error(res => {
          console.log('微信分享错误信息：', res)
        })
      })



    },
    rest () {
      this.form = {
        sex: '1',
        age: '18',
        height: '',
        weight: ''
      }
    },
    // 获取点击位置
    moveButton (event) {
      this.xuanfu = false
      // 获取点击位置相对于文档的坐标
      const clickedX = event.clientX;
      const clickedY = event.clientY;
      console.log(window.innerWidth);
      if (window.innerWidth < 500) {
        this.buttonPosition = {
          top: clickedY + 'px',
          left: clickedX + 'px'
        };
        this.$refs.floatingButton.style.top = clickedY + 'px';
        this.$refs.floatingButton.style.left = clickedX + 'px';
        this.xuanfu = true

      }
      // 更新悬浮按钮的位置

    },
    async submit () {
      //   if (this.form.sex==='') {
      //   return Toast.fail('请选择性别!')
      // }
      // if (this.form.age==='') {
      //   return Toast.fail('请输入年龄!')
      // }
      if (this.form.height === '') {
        return Toast.fail('请输入身高!')
      }
      if (this.form.weight === '') {
        return Toast.fail('请输入体重!')
      }

      this.$router.push({
        path: 'bmiResult',
        query: {
          sex: this.form.sex,
          height: this.form.height,
          weight: this.form.weight,
          age: this.form.age
        }
      })
      // this.getCode()
    },
    async getCode (type) {
      console.log(123, "获取code");

      let scope = "snsapi_userinfo"; //手动授权
      let info = {
        appid: "wxecd388507aac29b6"
      };
      // if (this.info.belong === 2) {
      //   info = {
      //     appid: "wx64571f72789c7dd3"
      //   };
      // } else if (this.info.belong === 3) {
      //   info = {
      //     appid: "wx109c3a30148f3fb8"
      //   };
      // } else if (this.info.belong === 7) {
      //   info = {
      //     appid = ""
      //   }
      // }

      // const res = await getCompanyById({
      //   id: this.info.company_id
      // });
      // info.appid = res.data.app_id;
      // !isCode
      // const token = sessionStorage.getItem("assice_token");
      // if (this.$route.query.code && !token) {
      //   this.getAccountOauthCodes();

      //   this.loading = true;
      //   console.log(this.$route.query.code, "我是code");

      //   // this.getOpenId(this.$route.query.code as string);
      // } else if (this.$route.query.code && token) {
      //   // TODO
      // } else {
      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      // channelLogin: this.$route.query.channelLogin || ""
      // nickname: this.$route.query.nickname || ""
      const redirectUrl = basAAA;
      // const redirectUrl = "http://localhost:8081";
      // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect`;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/bmiResult?sex=${this.form.sex}&age=${this.form.age}&height=${this.form.height}&weight=${this.form.weight}`
        )}&response_type=code&scope=${scope}#wechat_redirect`;
      // }
    },
  },
  mounted () {
    this.getWechatConfig()
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDiv {
  width: 100%;
  min-height: 667px;
  overflow: hidden;
  position: relative;
}
.topImg {
  width: 100%;
  position: absolute;
  z-index: -1;
  img {
    width: 100%;
    object-fit: contain;
  }
}
.form {
  z-index: 99;
  width: 340px;
  margin: 20px auto;
  margin-top: 160px;
  background-color: #fff;
  padding: 10px;

  border-radius: 10px;
  min-height: 360px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.32);
  overflow: hidden;
  .title {
    font-size: 22px;
    margin: 20px;
  }
  .gender {
    width: 350px;
    margin: 0 auto;
    margin-left: 50px;
  }
  .infos {
    .title {
      font-size: 14px;
      color: #999;
      margin: 10px 20px;
    }
  }
  .btn {
    display: flex;
    width: 350px;
    margin: 40px auto;
    text-align: center;
    align-items: center;
    .rest {
      color: #999;
      width: 120px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      margin: 10px;
    }
    .sub {
      color: #fff;
      background-color: #00c298;
      width: 190px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 10px;
    }
  }
}
.wrapper {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  height: 100%;
}

.block {
  width: 200px;
  height: 120px;
  img {
    width: 100%;
    object-fit: contain;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    min-height: 667px;
    overflow: hidden;
    margin: 0 auto;
    .topImg {
      width: 750px;
      position: absolute;
      z-index: -1;
      img {
        width: 100%;
        height: 320px;
        object-fit: cover;
      }
    }
    .form {
      z-index: 99;
      padding: 10px;
      width: 350px;
      margin: 20px auto;
      margin-top: 60px;
      background-color: #fff;
      border-radius: 10px;
      min-height: 360px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.32);
      padding: 10px;
      overflow: hidden;
      .title {
        font-size: 22px;
        margin: 20px;
      }
      .gender {
        width: 350px;
        margin: 0 auto;
        margin-left: 50px;
      }
      .infos {
        .title {
          font-size: 14px;
          color: #999;
          margin: 10px 20px;
        }
      }
      .btn {
        display: flex;
        width: 350px;
        margin: 40px auto;
        text-align: center;
        align-items: center;
        .rest {
          color: #999;
          width: 120px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          margin: 10px;
        }
        .sub {
          color: #fff;
          background-color: #00c298;
          width: 190px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 10px;
        }
      }
    }
  }
}
.floating-button {
  position: absolute;
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
}
</style>
<style >
.van-field {
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 10px;
}
</style>